import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AppStateService } from '../app-state.service';
import { forkJoin, Observable, map, tap, switchMap } from 'rxjs';
import {
  Lab,
  Link,
  Locale,
  LocaleService,
  ResultsDataResourcesService,
  WorkQueuesService,
  WorkspacesService,
} from '@lims-common-ux/lux';
import { Inject, Injectable } from '@angular/core';
import { ResultsDataResource } from '@lims-common-ux/lux/lib/data-resources/results-data-resource.interface';
import { TranslateService } from '@ngx-translate/core';
import { PageResource } from '../interfaces/pageResource.interface';
import { PAGE_RESOURCE } from '../application-init.service';
import { WorkspaceConfigService } from '../workspace/workspace/workspace-config.service';

@Injectable({ providedIn: 'root' })
export class LabResolver {
  private readonly resultLink: Link;

  constructor(
    private resultResourceService: ResultsDataResourcesService,
    private workspacesService: WorkspacesService,
    private translate: TranslateService,
    private appStateService: AppStateService,
    private workQueueService: WorkQueuesService,
    private workspaceConfigService: WorkspaceConfigService,
    private localeService: LocaleService,
    @Inject(PAGE_RESOURCE) resource: PageResource
  ) {
    this.resultLink = resource._links.results;
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<{ lab: Lab; locale: Locale }> {
    const labId = route.paramMap.get('lab');
    const lab = this.appStateService.labs.find((listLab) => listLab.id === labId);

    this.localeService.setUrlLocale(route.queryParams?.locale);

    this.appStateService.lab = lab;

    return this.resultResourceService.getResultsDataResource(this.resultLink, lab).pipe(
      tap(() => {
        this.translate.use(this.localeService.selectedLocale);
      }),
      switchMap((resource: ResultsDataResource) => {
        this.appStateService.configureSharedLinks(resource);
        return forkJoin([
          this.workspacesService.loadWorkspaces(resource._links.workspaces),
          this.workQueueService.getWorkQueues(),
        ]);
      }),
      tap(([workspacesResponse, workQueuesResponse]) => {
        this.appStateService.workQueues = workQueuesResponse;
        this.appStateService.workspaces = workspacesResponse;
        return this.translate.use(this.localeService.selectedLocale);
      }),
      switchMap(() => {
        // Configure manual diff keybindings on every lab set/change
        return this.workspaceConfigService.getLabWorkspaceConfig(this.appStateService.workspaceConfigSource, lab);
      }),
      map(() => ({ lab, locale: this.localeService.selectedLocale })) // lab and local update should both trigger route.data observable
    );
  }
}
